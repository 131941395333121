import React from "react"

// Components
import Accordion from "../accordion"

const Header = ({ title, data }) => {
  return (
    <div className="mb-4">
      <p className="font-medium text-xl mb-1">{title}</p>
      <p className="text-sm text-gray-700">
        {data.edges.length} vprašanj na to temo.{" "}
        <span role="img" aria-label="Finger pointing down">
          👇
        </span>
      </p>
    </div>
  )
}

const Content = ({ data }) => {
  return (
    <div className="grid grid-cols-1 gap-3">
      {data.edges.map(({ node }) => {
        return (
          <Accordion
            title={node.q}
            text={node.a}
            key={node.id}
            links={node.links}
            list={node.list}
          />
        )
      })}
    </div>
  )
}

const FaqGroup = ({ data, title }) => {
  return (
    <div>
      <Header title={title} data={data} />
      <Content data={data} />
    </div>
  )
}

export default FaqGroup
