import React from "react"
import { graphql, Link } from "gatsby"

// Components
import Layout from "../components/layout"
import Seo from "../components/seo"
import FaqGroup from "../components/faqs"
import CtaBoxes from "../components/promotions/ctaBoxes"
import { SectionHeadingLeft } from "../components/headings/index"

const FaqsPage = ({ location, data }) => {
  const { lr, install, payment, faq } = data

  return (
    <Layout path={location.pathname}>
      <Seo
        title="Pogosta Vprašanja | Lightroom Presets"
        lang="sl"
        description="Vse na enem mestu. Pobrskaj med najbolj pogosto zastavljenimi vprašanji. Mobile Lightroom Presets - Urejaj slike z enim klikom"
      />
      <section className="max-w-screen-xl mx-auto px-4 pt-10 sm:pt-16">
        <SectionHeadingLeft title="onePlace" />
        <p className="-mt-2">
          Pobrskaj med najbolj pogosto zastavljenimi vprašanji. (
          {lr.edges.length +
            install.edges.length +
            payment.edges.length +
            faq.edges.length}
          ){" "}
          <span role="img" aria-label="heart icon">
            ❤️
          </span>
        </p>
        <div className="grid  md:grid-cols-2 gap-6 mt-6  sm:mt-10">
          <div>
            <FaqGroup data={lr} title="Lightroom Preseti" />
            <div className="mt-6">
              <FaqGroup data={install} title="Navodila" />
            </div>
          </div>
          <div>
            <FaqGroup data={faq} title="Pogosta Vprašanja" />
            <div className="mt-6">
              <FaqGroup data={payment} title="Plačilo" />
            </div>
          </div>
        </div>
        <div></div>
      </section>
      <section className="mx-auto text-center max-w-screen-sm px-4 pb-10 pt-10 sm:pb-16">
        <p>
          Ni odgovora na tvoje vprašanje?{" "}
          <Link to="/contact" className="hover:underline">
            Kontaktiraj me direktno.{" "}
          </Link>
          <span role="img" aria-label="Wave icon">
            👋
          </span>
        </p>
      </section>
      {/* <section className="max-w-screen-xl mx-auto px-4 pb-10 sm:pb-16">
        <CtaBoxes />
      </section> */}
    </Layout>
  )
}

export const query = graphql`
  query faqQuery {
    lr: allQuestionsJson(filter: { type: { eq: "lr" } }) {
      edges {
        node {
          id
          q
          a
          type
          links {
            label
            href
          }
          list
        }
      }
    }
    install: allQuestionsJson(filter: { type: { eq: "install" } }) {
      edges {
        node {
          id
          q
          a
          type
          links {
            label
            href
          }
          list
        }
      }
    }
    payment: allQuestionsJson(filter: { type: { eq: "payment" } }) {
      edges {
        node {
          id
          q
          a
          type
          links {
            label
            href
          }
          list
        }
      }
    }
    faq: allQuestionsJson(filter: { type: { eq: "faq" } }) {
      edges {
        node {
          id
          q
          a
          type
          links {
            label
            href
          }
          list
        }
      }
    }
  }
`

export default FaqsPage
