import React from "react"

// Assets
import ArrowIcon from "../../images/icons/arrow-up.inline.svg"

const Title = ({ title, handleOpen, active }) => {
  return (
    <button
      onClick={handleOpen}
      className={`p-4 w-full justify-between text-left flex font-medium items-center ${
        active && "border-b bg-primary-30"
      } transition duration-150 hover:bg-primary-30`}
      style={{ fontSize: 15 }}
    >
      {title}
      <div
        className={`ml-1 transform transition duration-200 ${
          active ? "rotate-180" : "rotate-0"
        }`}
      >
        <ArrowIcon />
      </div>
    </button>
  )
}

export default Title
