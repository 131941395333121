import React, { useState } from "react"

// Components
import Title from "./title"
import Content from "./content"

const Accordion = ({ title, text, links, list, classes }) => {
  const [active, setActive] = useState(false)

  const handleOpen = () => {
    return setActive(!active)
  }

  return (
    <div className="border border-gray-200">
      <Title title={title} handleOpen={handleOpen} active={active} />
      {active ? (
        <Content text={text} links={links} list={list} classes={classes} />
      ) : (
        <div className="invisible h-0">{text}</div>
      )}
    </div>
  )
}

export default Accordion
