import React from "react"
import { Link } from "gatsby"

const List = ({ list }) => {
  return (
    <ul className="list-disc ml-6 mt-2">
      {list.map(item => (
        <li key={item} className="py-0.5">
          {item}
        </li>
      ))}
    </ul>
  )
}

const Links = ({ links }) => {
  return links.map(({ href, label }) => {
    if (href.includes("https")) {
      return (
        <a
          key={href}
          href={href}
          target="_blank"
          rel="noreferrer"
          className="text-blue-600 hover:underline mt-2 block"
        >
          {label}
        </a>
      )
    } else {
      return (
        <Link
          to={href}
          key={href}
          className="text-blue-600 hover:underline mt-2 block"
        >
          {label}
        </Link>
      )
    }
  })
}

const Content = ({ text, links, list, classes }) => {
  return (
    <div
      className={` ${classes ? classes : "py-6 px-4 bg-white"}`}
      style={{ fontSize: 15 }}
    >
      <p>{text}</p>
      {list && <List list={list} />}
      {links && <Links links={links} />}
    </div>
  )
}

export default Content
